import { useEffect } from "react";
import "../styles/globals.css";
import { AppPropsWithLayout } from "../utils/datautils";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
  
    isBrowser &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'categoryProduct',
        categoryProduct: 'rcs',
      });
  }, [])

  return getLayout(<Component {...pageProps} />);
}

export default MyApp;
